<template>
  <main class="notice_detail">
    <div class="ndetail_wrap">
      <h1>{{ item.title }}</h1>
      <div class="ndetail_date">
        <p>{{ item.regDtTm }}</p>
        <input type="text" v-model="copy_url" class="hidden" />
        <img src="@/assets/icons/copy-url.png" alt="copy-url" @click="copy" />
      </div>
      <div class="ndetail_description">
        <p>
          <viewer :initialValue="initialValue" v-if="initialValue != null" />
        </p>
        <p class="files">
          <a
            :href="`${this.fileDown_url}${item.attachCdOne}`"
            target="_blank"
            v-if="item.attachOne"
          >
            {{ item.attachOne.fileNm }}
          </a>
          <a
            :href="`${this.fileDown_url}${item.attachCdTwo}`"
            target="_blank"
            v-if="item.attachTwo"
          >
            {{ item.attachTwo.fileNm }}
          </a>
          <a
            :href="`${this.fileDown_url}${item.attachCdThree}`"
            target="_blank"
            v-if="item.attachThree"
          >
            {{ item.attachThree.fileNm }}
          </a>
        </p>
      </div>
      <div class="list_button">
        <p class="pdetail_prev" @click="readPrev" v-if="isPrev">
          <button type="button">이전</button>
          <span>{{ prevTitle }}</span>
        </p>
        <p class="pdetail_next" @click="readNext">
          <button type="button" v-if="isNext">다음</button>
          <span>{{ nextTitle }}</span>
        </p>
      </div>
      <div class="pdetail_btn">
        <button class="pdetail_list_btn" @click="list">목록</button>
      </div>
    </div>
  </main>
</template>
<script>
import { Viewer } from "@toast-ui/vue-editor";
import { mapGetters } from "vuex";
import client from "api-client";

export default {
  components: {
    viewer: Viewer,
  },
  data() {
    return {
      url: "/api/board/notice",
      item: "",
      seq: "",
      prevSeq: "",
      nextSeq: "",
      prevTitle: "",
      nextTitle: "",
      initialValue: null,
      copy_url: "",
      fileDown_url: process.env.VUE_APP_API_URL + "/api/attach/",
      isPrev: true,
      isNext: true,
    };
  },
  methods: {
    list() {
      this.$router.push({
        name: "Notice",
      });
    },
    read() {
      this.seq = this.$route.params.seq;
      client.boardNoticeView(this.seq).then(
        (response) => {
          const { data } = response;
          this.item = data;
          this.getData(this.item);
        },
        (error) => {
          console.log(error.response.status);
        }
      );
    },
    getData(item) {
      this.initialValue = item.contentEditor;
    },
    readPrev() {
      client.boardNoticePrev(this.seq).then(
        (response) => {
          const { data } = response;
          this.prevSeq = data.seq;
          this.$router.push({
            name: "NoticeSeq",
            params: {
              seq: this.prevSeq,
            },
          });
        },
        (error) => {
          console.log(error);
        }
      );
    },
    getPrevTitle() {
      client.boardNoticePrev(this.seq).then(
        (response) => {
          const { data } = response;
          this.prevTitle = data.title;
        },
        (error) => {
          this.isPrev = false;
          console.log(error);
        }
      );
    },
    readNext() {
      client.boardNoticeNext(this.seq).then(
        (response) => {
          const { data } = response;
          this.nextSeq = data.seq;
          this.goNext(this.nextSeq);
        },
        (error) => {
          console.log(error);
        }
      );
    },
    goNext(nextSeq) {
      this.$router.push({
        name: "NoticeSeq",
        params: {
          seq: nextSeq,
        },
      });
    },
    getNextTitle() {
      client.boardNoticeNext(this.seq).then(
        (response) => {
          const { data } = response;
          this.nextTitle = data.title;
        },
        (error) => {
          this.isNext = false;
          console.log(error);
        }
      );
    },
    copy() {
      this.$copyText(this.copy_url);
      alert("URL을 복사하였습니다.");
    },
  },
  created() {
    this.read();
    this.getPrevTitle();
    this.getNextTitle();
    this.copy_url = document.location.href;
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
};
</script>
